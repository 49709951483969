<template>
  <card-template
    :item="item"
    :selected="selected"
    :compact="compact"
    class="card-email"
    :class="{
      'card-email--compact': !!compact,
    }"
  >
    <template #label-after>
      <notification
        v-if="compact && item.thread && item.thread.length"
        :count="item.thread.length"
      />
    </template>
    <template #content>
      <div class="property property--subject" v-html="item.subject">
        <notification
          v-if="item.thread && item.thread.length"
          :count="item.thread.length"
        />
      </div>
      <div class="property property--from">From: {{ item.email_from }}</div>
      <div class="property property--to">to {{ item.email_to }}</div>
      <div class="property property--description" v-html="item.html_body"></div>
    </template>
    <template #content-compact>
      <div class="property property--subject" v-html="item.subject">
      </div>
      <div class="property property--date">
        {{ FormatDate(item.date) }}
      </div>
    </template>
  </card-template>
</template>

<script>
import cardTemplate from "./card-base-template.vue";
import {FormatDate} from "@/plugins/functions"
const notification = () =>
  import(
    "@/components/dashboard-components/checklist/components/notification.vue"
  );

export default {
  name: "card-email",
  components: {
    cardTemplate,
    notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods:{
    FormatDate,
  }
};
</script>

<style lang="scss" scoped></style>
